<template>
    <div>
        <div class="line">
            <label name="label_nom" for="name">E-mail address</label>
            <input type="text" id="email" size="50" name="user_name" placeholder="email" required>
        </div>
            <button v-on:click="sendEmail()">Reset password</button>

        <div v-if="display" class="line">
            <label name="label_nom" for="name">E-mail address</label>
            <input type="text" id="email" size="50" name="user_name" placeholder="email" required>
        </div>
    </div>
</template>

<script>
const axios = require('axios');
export default {
    data: () => ({
        username: '',
        token: undefined,
        display: false
    }),
    methods : {
        async sendEmail() {
            const emailAddress = document.getElementById("email").value
            if (emailAddress == "admin@root.com") {
                return
            }
            else {
                var data = {
                    email: emailAddress
                }
                var self = this;
                await axios.post('https://api.fkv-vss.com/api/CheckEmail', data).then(async function (response) {
                    if (response.data.message === 'This E-mail address exists') {
                        self.username = response.data.username;
                        self.token = response.data.token;
                        localStorage.setItem("email", emailAddress);
                        data = {
                            token: self.token,
                            email: emailAddress
                        }
                        await axios.post('https://api.fkv-vss.com/api/sendEmail', data).then(function (response) {
                            if (response.data.message == "An e-mail has been sent to you. \nCheck your mailbox and your spams") {
                                alert(response.data.message)
                            }
                        });
                    }
                    else {alert(response.data.message)}
                })
            }
        }
    }
}
</script>

<style scoped>
label {
  display: block;
  text-align: left;
  margin-left: 18%;
}
.line{
  width: 50%;
  margin-left: 25%;
}
* {
    width: 100%;
    margin-left: 0%;
}
label {
    text-align: left;
    margin-left: 0%;
}
button{
    width: 163px;
}
@media only screen and (max-width: 820px) {
    .line {
        width: 100%;
        margin-left: 0%;
    }
}
@media only screen and (max-width: 474px) {
  * {
    width: 100%;
    margin-left: 0%;
  }
  label {
    text-align: left;
    margin-left: 0%;
  }
  button{
    width: 100%;
  }  
}
</style>